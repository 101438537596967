import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { Seo } from '../components'

const NotFoundPage = () => {
  useEffect(() => {
    navigate('/')
  }, [])
  return (
    <>
      <Seo title="Redirecting..." />
    </>
  )
}

export default NotFoundPage
